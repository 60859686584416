import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  IndividualReceiverListPage,
  BeneficiariesListPage,
  ClaimPaymentListPage,
  ClaimPaymentPage,
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  UpdatePasswordPage,
  ResetPasswordPage,
  DashboardPage,
  KycLevelsPage,
  OutgoingTransferPage,
  OutgoingTransferListPage,
  ChatPage,
  Page500,
  Page403,
  Page404,
  HomePage,
  MaintenancePage,
  SignupPage,
  IndividualReceiverCreatePage,
  IndividualReceiverEditPage,
  BeneficiaryCreatePage,
  BeneficiaryEditPage,
  OutgoingTransferDetailsPage,
  UserAccountPage,
  UserKyc,
  ProgrammedLiensListPage,
  BusinessDetails,
  Transactions,
  WithdrawAccounts,
  TransactionReportPage,
} from './elements';
import { PATH_DASHBOARD } from './paths';
import FinancialInfo from 'src/pages/dashboard/financialInfo/FinancialInfoPage';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'signup',
          element: (
            <GuestGuard>
              <SignupPage />
            </GuestGuard>
          ),
        },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },

            { path: 'update-password', element: <UpdatePasswordPage /> },

            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <DashboardPage /> },
        {
          path: PATH_DASHBOARD.user.root,
          children: [
            {
              element: <Navigate to="/dashboard/user/business-details" replace />,
              index: true,
            },
            { path: 'business-details', element: <UserAccountPage /> },
            { path: 'kyc', element: <UserKyc /> },
          ],
        },
        {
          path: 'beneficiaries',
          children: [
            {
              element: <Navigate to="/dashboard/beneficiaries/list" replace />,
              index: true,
            },
            {
              path: 'edit/:userId',
              element: <BeneficiaryEditPage />,
            },

            { path: 'list', element: <BeneficiariesListPage /> },
            {
              path: 'create',
              element: <BeneficiaryCreatePage />,
            },
          ],
        },
         {
          path: 'outgoing-transfer',
          children: [
            {
              element: <Navigate to="/dashboard/outgoing-transfer/list" replace />,
              index: true,
            },
            { path: 'list', element: <OutgoingTransferListPage /> },
            { path: 'create', element: <OutgoingTransferPage /> },
            { path: ':id', element: <OutgoingTransferDetailsPage /> },
          ],
        },
        {
          path: 'claim-payment',
          children: [
            {
              element: <Navigate to="/dashboard/claim-payment/list" replace />,
              index: true,
            },
            // {
            //   path: 'edit/:userId',
            //   element: <BeneficiaryEditPage />,
            // },

            { path: 'list', element: <ClaimPaymentListPage /> },
            
            {
              path: 'claim/:id',
              element: <ClaimPaymentPage />,
            },
            {
              path: 'create',
              element: <BeneficiaryCreatePage />,
            },
          ],
        },
        {
          path: 'financial-account',
          children: [
            {
              element: <Navigate to="/dashboard/financial-account/list" replace />,
              index: true,
            },
            { path: 'list', element: <FinancialInfo /> },
          ],
        },
            {
          path: 'contract-payable',
          children: [
            {
              element: <Navigate to="/dashboard/contract-payable/list" replace />,
              index: true,
            },
       

            { path: 'list', element: <ProgrammedLiensListPage /> },
          
          ],
        },
        {
          path: 'business-details',
         element: <BusinessDetails/>
        },
        {
          path: 'transactions',
         element: <Transactions/>
        },
        {
          path: 'withdraw-accounts',
         element: <WithdrawAccounts/>
        },

        {
          path: 'reports',
          children: [
            {
              element: <Navigate to="/reports/transaction" replace />,
              index: true,
            },
            { path: 'transaction', element: <TransactionReportPage /> },
          ],
        },
        
       
        {
          path: 'chat',
          children: [
            { element: <ChatPage />, index: true },
            { path: 'new', element: <ChatPage /> },
            { path: ':conversationKey', element: <ChatPage /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        {
          element: (
            <GuestGuard>
              <HomePage />
            </GuestGuard>
          ),
          index: true,
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

import { Link as RouterLink } from "react-router-dom";
// @mui
import { Alert, Tooltip, Stack, Typography, Link, Box } from "@mui/material";
// auth
import { useAuthContext } from "../../auth/useAuthContext";
// routes
import { PATH_AUTH } from "../../routes/paths";
// layouts
import LoginLayout from "../../layouts/login";
//
import AuthLoginForm from "./AuthLoginForm";
import AuthWithSocial from "./AuthWithSocial";
import { useSelector } from "react-redux";
import { useLocales } from "src/locales";
import LanguagePopover from "src/layouts/dashboard/header/LanguagePopover";
import Iconify from "src/components/iconify";

// ----------------------------------------------------------------------

export default function Login() {
  const { mtoConfig } = useSelector((state) => state.config);
  const { t } = useLocales();

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: "relative" }}>
        <Typography variant="h4">
          {t("signInTo", { name: "IPUSA" })}
        </Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">{t("newUser")}</Typography>

          <Link
            component={RouterLink}
            to={PATH_AUTH.singup}
            variant="subtitle2"
          >
            {t("createAccount")}
          </Link>
        </Stack>
      </Stack>

      <AuthLoginForm />
      <Stack sx={{ my: 3 }} spacing={1}>
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: "600",
            color: "#284f93",
            display: "flex",
            alignItems: "flex-start",
            gap: "5px",
          }}
        >
          {" "}
          <Iconify icon="wpf-note" width={20} />
          {t("loginNoticePassword")}
        </Typography>
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: "600",
            color: "#284f93",
            display: "flex",
            alignItems: "flex-start",
            gap: "5px",
          }}
        >
          {" "}
          <Iconify icon="wpf-note" width={16} />
          {t("loginNoticeNoPassword")}
        </Typography>
        {/* <Typography variant="body2">{t("loginNoticePassword")}</Typography>
        <Typography variant="body2">{t("loginNoticeNoPassword")}</Typography> */}
      </Stack>
    </LoginLayout>
  );
}

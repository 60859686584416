import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { Stack, TextField, Tooltip, FormHelperText } from "@mui/material";
import Iconify from "../iconify/Iconify";

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export default function RHFTextField({
  name,
  helperText,
  tooltip,
  onChange = () => {},
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            fullWidth
            // InputLabelProps={{ style: { fontStyle: 'italic' } }}
            value={
              typeof field.value === "number" && field.value === 0
                ? ""
                : field.value
            }
            error={!!error}
            helperText={
              <FormHelperText sx={{ fontSize: 14, margin: 0, padding: 0 }}>
                {error ? error?.message : helperText}
              </FormHelperText>
            }
            onChange={(e) => {
              field.onChange(e);
              onChange(e);
            }}
            {...other}
          />
        )}
      />
      {tooltip && (
        <Tooltip title={tooltip}>
          <Iconify icon="eva:info-outline" width={16} sx={{ mx: 0.5 }} />
        </Tooltip>
      )}
    </Stack>
  );
}

import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import { Box, Link, Typography, Tooltip } from "@mui/material";
// auth
import { useAuthContext } from "../../../auth/useAuthContext";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import { CustomAvatar } from "../../../components/custom-avatar";
import { startCase } from "lodash";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();
  let displayName;
  displayName = `${user?.first_name} ${user?.last_name}`;

  displayName = startCase(displayName);

  return (
    <Link
      component={RouterLink}
      // to={PATH_DASHBOARD.user.account}
      underline="none"
      color="inherit"
      sx={{width: '100%'}}
    >
      <StyledRoot>
        <CustomAvatar
          src={user?.photoURL}
          alt={displayName}
          name={displayName}
        />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {displayName}
          </Typography>
          <Tooltip title={user?.customer?.business_name}>
            <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
              {user?.customer?.business_name}
            </Typography>
          </Tooltip>
        </Box>
      </StyledRoot>
    </Link>
  );
}

const cn = {
  demo: {
    title: `Chinese`,
    introduction: `Lorem Ipsum 只是印刷和排版行业的虚拟文本。 自 1500 年代以来，Lorem Ipsum 一直是行业的标准虚拟文本，当时一位不知名的印刷商采用了一种类型的厨房并将其加扰以制作类型样本书。 它不仅经历了五个世纪，而且经历了电子排版的飞跃，基本保持不变。 它在 1960 年代随着包含 Lorem Ipsum 段落的 Letraset 表的发布而流行起来，最近还随着 Aldus PageMaker 等桌面出版软件（包括 Lorem Ipsum 的版本）而普及.`,
  },
  docs: {
    hi: `你好`,
    description: `需要帮忙？\n 请查看我们的文档.`,
    documentation: `文件`,
  },

  //Landing Page
  landingHeading: "发送国际付款方式",

  // Forgot Password
  forgotPasswordTitle: "忘记密码了吗?",
  forgotPasswordDescription:
    "请输入与您的帐户关联的电子邮件地址，我们将通过电子邮件向您发送重置密码的链接",
  sendRequest: "发送请求",
  returnSignIn: "返回登录",
  registrationDeclaration: "我已详细阅读并同意",
  ipuTc: "议会联盟条款和条件",
  ipuPp: "议会联盟隐私政策",
  registrationDecalartionTwo:
    "法人需亲自填写KYC调查问卷并上传文件。若无法办理，请提供《授权书》",

  // Navigation
  dashboard: `仪表板`,
  remittance: `汇款`,
  receivers: `收件人`,
  userAccount: `用户帐户`,
  userProfile: `用户资料`,
  userKybKyc: `用户 KYB/KYC`,
  support: `支持`,

  // Dashboard
  goodMorning: `早上好`,
  goodAfternoon: `下午好`,
  goodEvening: `晚上好`,
  sendNow: `现在发送`,
  receiveNow: "立即接收",
  totalLiens: "合同总数",
  incomingTransfers: "接收中的转账",
  internalTransfer: "内部转账",
  outgoingTransfers: "转出中的转账",
  incoming: "接收中",
  internal: "内部",
  outgoing: "转出中",
  totalRemittanceAmount: `汇款总额`,
  remittancePending: `汇款待处理`,
  remittanceCompleted: `汇款完成`,
  remittanceRejected: `汇款被拒绝`,
  remittanceAmount: `汇款金额`,
  numberOfRemittances: `汇款次数`,
  created: `创建`,
  completed: `完成`,
  pending: `待办的`,
  rejected: `拒绝`,
  daily: `日常的`,
  weekly: `每周`,
  monthly: `每月`,
  yearly: `每年`,
  loading: `加载中`,
  noDataAvailable: `无可用数据`,
  countrywiseRemittance: `国家汇款`,
  beneficiarywiseRemittance: `收款人汇款`,
  amount: `数量`,
  count: `数数`,
  total: `全部的`,

  claimPayment: "索赔付款",
  programmedLiens: "合同应付款项",
  transactions: "交易",
  reports: "报告",
  transactionReport: "交易报告",
  accounts: "账户",
  masterAccounts: "主账户",
  withdrawAccounts: "提款账户",
  newWithdraw: "新提款",
  withdraw: "提款",
  landingGreeting: "您好，欢迎回来",
  signInTo: "登录到 {{name}}",
  newUser: "新用户？",
  createAccount: "创建账户",
  forgotPassword: "忘记密码？",
  login: "登录",
  loginNoticePassword: "如果您刚刚注册，请单击电子邮件中收到的链接来设置密码",
  loginNoticeNoPassword: "如果您没有收到电子邮件，请单击忘记密码",
  alreadyUser: "已有账户？",
  authRepDetails: "授权代表的详细信息",
  clickNextInfo:
    "点击“下一步”，即表示您已阅读并理解了IPUSA隐私与Cookie政策，并同意其中的条款。",
  companyDetails: "公司详情",
  companyDetailsTooltip: "如在您所在国家政府注册的详细信息",
  regBusAddress: "企业注册地址",
  sameAsMailingAdd: "企业通讯地址与注册地址相同",
  busAddress: "企业通讯地址",
  anticipatedBusiness: "预期的业务活动",
  avgTransactionSize: "平均交易规模",
  frequency: "频率",
  volume: "体积",
  jurisdication: "管辖权",
  canSelectMultipleCountries: "您可以选择多个国家",
  jurisdicationCountries: "管辖国家",
  mccCode: "MCC代码",
  taxId: "税号",
  declarationTitle: "信息准确性和真实性声明",
  decalarationDescription:
    "我特此确认，据我所知，所提供的所有信息和文件均真实、准确且具有法律效力。我承认文档中提供的任何虚假或误导性信息可能会导致法律后果，包括但不限于注册过程被拒绝或采取法律行动。",
  positionRole: "职位角色",
  identificationDocNumber: "身份证明文件号码",
  reviewedDecalaration: "我已审核并接受",
  reviewTermCondition: "条款、条件和合同。",

  // Claim Payment
  claim: "索赔",
  claimFundsTitle: "索赔从{{country}} ({{company}}) 接收的 {{amount}}$ 资金",
  addSupportingDocs: "添加支持文件",
  submitClaimReq: "提交索赔请求",
  uploadDoc: "上传文件",
  updateDoc: "更新文档",
  updateClaim: "更新声明",

  // Programmed Liens
  totalPendingLiens: "待定合同总数",
  totalSettledLiens: "已结算合约总数",
  totalBorrowingsLifetime: "部分结算合约总数",
  lienId: "留置权 ID",
  relatedTxn: "相关交易",
  lienAmount: "留置权金额",
  settledAmount: "结清金额",
  lender: "贷款人",
  closureTxn: "关闭交易",
  unsettled: "未结清",
  partiallySettled: "部分结清",
  fullySettled: "完全结清",
  liens: "合同",
  lien: "合同",
  closureTxns: "关闭交易",
  id: "编号",

  sender: "发送者",
  maker: "制造者",
  checker: "检查员",

  externalIncoming: "外部收入",
  externalOutgoing: "外部支出",
  newInternalTransfer: "新的内部转账",
  subAcBalance: "子账户余额",

  selectPaymentMethod: "请选择支付方式",
  selectPaymentAccount: "请选择账户",

  // Remittance List
  remittanceList: `汇款清单`,
  list: `列表`,
  newRemittance: `新汇款`,
  createdDate: `创建日期`,
  senderMTO: `发件人 MTO`,
  endBeneficiary: `最终受益人`,
  sentValue: `发送值`,
  receivedValue: "收到的值",
  status: "地位",
  paymentQr: `付款二维码`,
  dense: `稠密`,
  create: `创建`,

  // Create Remittance
  clientInfo: "客户信息",
  beneficiaryInfo: "受益人信息",
  transferDetails: "转账详情",
  previewAndSubmit: "预览并提交",
  type: "类型",
  purpose: "目的",
  relationship: "关系",
  purposeDesc: "目的描述",
  documents: "文件",
  remittanceDecl: "此交易是由发送者亲自完成的，而不是代表其他人完成的。",
  addNewDoc: "添加新文档",
  documentType: "文档类型",
  documentName: "文档名称",
  upload: "上传",
  docUploadInfo: "*您可以上传图像、pdf、文档或 excel 文件。",

  cancel: "取消",
  dropOrSelectFile: "拖放或选择文件",
  dropFilesOrClick: "将文件拖放到此处或单击",
  browse: "浏览",
  thoroughYourMachine: "通过你的机器",
  receiver: "接收者",
  addNewReceiver: "添加新接收者",
  receiverAccount: "收款账户",

  amountToRemit: "汇款金额",
  description: "描述",
  transferAmount: "转账金额",
  senderMtoFee: "发件人 费用",
  beneficiaryMtoFee: "受益人 费用",
  otherFee: "其他费用",
  totalFee: "总费用",
  totalDepositAmount: "总存款金额",
  amountToPayout: "付款金额",
  purposeOfRemittance: "汇款目的",
  conversionRate: "兑换率",
  dateOfRemittance: "汇款日期",
  createdBy: "由...制作",
  firstName: "名",
  lastName: "姓",
  businessName: "企业名称",
  primaryContactName: "主要联系人姓名",
  venmo: "文莫",
  paypal: "贝宝",
  email: "电子邮件",
  emailAddress: "电子邮箱地址",
  reEmailAddress: "重新输入电子邮件地址",
  bankName: "银行名",
  branch: "分支",
  accountNumber: "帐号",
  leiCode: "LEI 代码",
  ibanCode: "IBAN码",
  swiftBicCode: "SWIFT BIC 代码",
  transitNumber: "转接号码",
  abaRoutingNumber: "ABA 路由号码",
  ifscCode: "IFSC 代码",
  address: "地址",
  remittanceDetails: "汇款详情",
  senderDetails: "发件人详细信息",
  phone: "电话",
  city: "城市",
  province: "省",
  country: "国家",
  targetCountry: "目标国家",
  beneficiaryDetails: "受益人详情",
  financialDetails: "财务详情",
  back: "后退",
  next: "下一个",
  confirmAndSubmit: "確認並提交",
  paymentOrderNumber: "付款订单号",
  depositType: "存款类型",
  senderMto: "发送者 MTO",
  receiverMto: "接收方 MTO",
  date: "日期",
  details: `细节`,
  statusHistory: "状态历史",
  payments: "付款",
  remittanceInitiated: "汇款已发起",
  paymentReceivedFromCustomer: "收到客户付款",
  outgoingPaymentOrderAssigned: "已分配付款单",
  passedAmlKycChecks: "通过 AML/KYC 检查",
  successfullyArrivedinDestinationCountry: "成功抵达目的地国",
  transferredtoEndBeneficiary: "转移至最终受益人",
  remarks: "评论",
  updatedBy: "更新者",
  action: "行动",
  noData: "没有数据",
  senderAddress: "发件人地址",
  xrplTag: "XRPL 标签",
  edit: "编辑",
  receiver: "接收器",
  receiverType: "接收器类型",
  companyInformation: "公司资料",
  lineOfBusiness: "业务范围",
  corporateType: "法人类型",
  contactDetails: "联系方式",
  primaryContactDesignation: "主要联系人指定",
  businessOperatingAddress: "营业地址",
  countryOperation: "运营国家/地区",
  addressLineOne: "地址行一",
  addressLineTwo: "地址线二",
  addressLineThree: "地址第三行",
  taxId: "税号",
  accountHolderName: "账户持有人姓名",
  accountType: "账户类型",
  bankAddress: "银行地址",
  paymentReference: "付款参考",
  senderCountry: "发件人国家/地区",
  fundingSource: "资金来源",

  newReceiver: "新接收者",
  userInfo: "用户信息",
  businessReceiversList: "商业接收者名单",
  beneficiaryEmailTooltip: "收款人将通过此电子邮件地址收到有关汇款的最新信息",
  createBeneficiary: "创建受益人",
  outgoingTransfersListTitle: "传出转账：列表",
  outgoingTransferCreateTitle: "传出传输：创建",
  outgoingTransfersList: "汇出转账列表",
  outgoingTransfer: "汇出转账",
  outgoingTransfers: "汇出转账",
  newOutgoingTransfer: "新汇出转账",
  financialInfo: "财务信息",
  businessInfo: "企业信息",
  beneficiaryCreate: "受益人：创建",
  newBeneficiary: "新受益人",
  beneficiary: "受益人",
  beneficiaryList: "受益人名单",
  businessInfo: "企业信息",
  changePassword: "更改密码",
  user: "用户",
  accountSettings: "账户设置",
  name: "姓名",
  invitationCode: "邀请码",
  primaryContactNumber: "主要联系电话",
  secondaryContactNumber: "辅助联系电话",
  primaryContact: "主要联系人",
  secondaryContact: "次要联系人",
  dob: "出生日期",
  designation: "职位",
  passportNumber: "护照号码",
  postalCode: "邮政编码",
  businessDetails: "企业详情",
  doingBusinessAs: "营业名称",
  legalEntity: "法人实体",
  businessCategory: "企业类别",
  businessSubCategory: "企业子类别",
  categoryOthers: "类别 其他",
  companyType: "公司类型",
  companyWebsite: "公司网站",
  companyWebsiteOptional: "公司网站（可选）",
  incorporationYear: "成立年份",
  registrationNumber: "注册号码",
  registeredBusinessAddress: "注册企业地址",
  businessCommunicationAddress: "企业通讯地址",
  oldPassword: "旧密码",
  newPassword: "新密码",
  confirmNewPassword: "确认新密码",
  saveChanges: "保存更改",

  // KYC
  kyc: {
    kyc: "了解您的客户",
    KybKycDocuments: "企业认证/客户身份认证文件",
    kycForm: "客户身份认证表格",
    startKyb: "开始企业认证",
    verificationCompleted: "验证完成",

    notDone: {
      title: "请验证您的商家账户以开始使用",
      info1: `为协助政府打击恐怖主义融资和
              洗钱活动，联邦法律要求所有金融机构获取、验证并记录识别
              每个开立账户的法人和个人的信息。`,
      info2: `为您的理解：在开立账户时，我们将要求提供
              您的姓名、地址、出生日期以及
              开立账户的法人的相关文件。`,
      info3: `这包括有关其控制人和实益
              拥有人的信息，以便我们能够识别您和您代表的法人。`,
      info4: `此外，我们可能需要您的身份证明文件、
              法人及与该法人相关的任何附属实体或个人
              以开立账户。`,
      info5: `账户验证过程通常需要大约
              1-3个工作日。`,
    },
    inProgress: {
      title: "账户验证进行中",
      info: `账户验证过程通常需要大约 1-3
            个工作日。`,
    },

    rejected: {
      title: "账户验证失败",
      info: `您的企业认证失败，请点击下面的按钮以查看并
              更新必要的信息以开始您的业务。`,
      btnText: "查看并更新",
    },
  },
  areYouKeyController: "您是企业的关键控制者吗?",
  authorizedDob: "授权代表的出生日期",
  keyControllerDesignation: "关键控制人的指定权限",
  sourceOfWealth: "财富来源",
  citizenship: "国籍",
  companyParent: "该公司有母公司或子公司吗?",
  note: "笔记",
  personalDocNote: "请上传您的护照和地址证明（例如水电费账单或银行对账单).",
  shareholderNote:
    "我们需要拥有公司 10% 或以上股份的所有最终受益所有人 (UBO) 的详细信息.",
  idProof: "身份证明",
  addressProof: "地址证明",
  addDocument: "添加文档",
  otherDocument: "其他文件",
  uploadContinue: "上传并继续",
  companyRegistrationDate: "公司注册日期",
  countryOfRegistration: "注册国家/地区",
  followingDocumentsRequired: "需要以下文件",
  proofOfAddress: "地址证明",
  articleIncorporation: "公司章程",
  ownerProof: "10%或以上的所有者证明",
  keyControllerProof: "关键控制人证明（总监级及以上组织架构）",
  photoId: "带照片的身份证件",

  pep: "政治敏感人物",
  yes: "是",
  no: "否",
  authRepDob: "授权代表的出生日期",
  authRepDes: "授权代表的职称",
  residentialAddress: "住宅地址",
  close: "关闭",
  documentFront: "文件正面",
  documentBack: "文件背面",
  onlyShareholder: "您是公司唯一的股东/最终受益人吗？",
  isShareholder: "您是公司的股东/最终受益人吗？",
  entityType: "实体类型",
  individual: "个人",
  company: "公司",
  enterOwnership: "输入您的持股比例%",
  shareholder: "股东/最终受益人",
  shareholders: "股东们",
  remove: "移除",
  contact: "联系电话",
  shareholdingPercentage: "持股比例",
  add: "添加",
  updateAndContinue: "更新并继续",
  legalCompanyName: "公司法定名称",
  doingBusinessAsOptional: "商业名称（DBA/别名）（可选）",
  registeredLegalEntity: "您的业务注册法律实体",
  companyWebsiteUrl: "公司网站 URL（可选）",
  occupation: "职业",
  enterOccupation: "输入您的职业",
  commAddressSameAsRegAddress: "业务通信地址与注册地址相同",
  businessCommMailingAddress: "业务通信/邮寄地址",
  postalZipCode: "邮政/邮编",
  businessCommAddress: "业务通信地址",
  businessRegAddress: "商业注册地址",
  otherInfo: "其他信息",
  timezone: "时区",
  businessBeneficiaryOwnershipDoc: "业务受益所有权文件",
  taxIdDoc: "税号文件",
  incorporationDoc: "公司注册文件",
  addressVerificationDoc: "地址验证文件",
  goodStandingDoc: "良好信誉证明",
  uploadAndContinue: "上传并继续",
  ownership: "所有权",
  personalInfo: "个人信息",
  personalDocs: "个人文件",
  businessDocs: "业务文件",
  businessOwnership: "业务所有权",
  preview: "预览",
  lastUpdatedOn: "最后更新于",
  newTicket: "新工单",
  chat: "聊天",
  all: "全部",
  openTkts: "开放工单",
  new: "新建",
  closed: "已关闭",
  open: "开放",
  selectConvo: "选择一个对话开始聊天。",
  closeTicket: "关闭工单",
  reopenTicket: "重新打开工单",
  delete: "删除",
  areYouSure: "您确定要",
  theTicket: "门票",
  ticket: "工单",
  reopen: "重新打开",
  goToKyc: "前往了解您的客户",
  typeMessage: "输入消息",
  message: "信息",
  home: "家",
  logout: "登出",

  app: `应用程序`,
  shop: `商店`,
  blog: `博客`,
  post: `邮政`,
  mail: `电子邮件`,
  cards: `牌`,
  posts: `帖子`,
  kanban: `登机`,
  general: `一般的`,
  banking: `银行业`,
  booking: `预订`,
  profile: `轮廓`,
  account: `帐户`,
  product: `产品`,
  invoice: `发票`,
  checkout: `退房`,
  calendar: `日历`,
  analytics: `分析`,
  ecommerce: `电子商务`,
  management: `管理`,
  menu_level: `菜单级别`,
  menu_level_2a: `菜单级别 2a`,
  menu_level_2b: `菜单级别 2b`,
  menu_level_3a: `菜单级别 3a`,
  menu_level_3b: `菜单级别 3b`,
  menu_level_4a: `菜单级别 4a`,
  menu_level_4b: `菜单级别 4b`,
  item_disabled: `项目已禁用`,
  item_label: `项目标签`,
  item_caption: `项目标题`,
  item_external_link: `项目外部链接`,
  other_cases: `其他情况`,
  item_by_roles: `按角色逐项`,
  only_admin_can_see_this_item: `只有管理员可以看到这个项目`,
  change_user_password: "修改用户密码!",
  temporary_password: "临时密码",
  update_password: "更新密码",
  password: "密码",
};

export default cn;

import PropTypes from "prop-types";
// @mui
import {
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

OtpDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  action: PropTypes.node,
  onClose: PropTypes.func,
  loading: PropTypes.any
};

export default function OtpDialog({
  title,
  action,
  open,
  onClose,
  onSubmit,
  loading,
  ...other
}) {
  const [otp, setOtp] = useState("");
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      <DialogContent sx={{ typography: "body2" }}>
        <MuiOtpInput value={otp} onChange={setOtp} length={6} autoFocus />
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            setOtp("");
            onClose();
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          onClick={() => {
            onSubmit(otp);
          }}
          loading={loading}
        >
          Verify OTP
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
